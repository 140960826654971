import React from  'react';
import { Link } from 'react-router-dom';
import Headline from '../../../components/Headline';

import './styles.css';

//API FAKE
import * as data from '../../../data';

export default function PostBlog() {
    return(
        <section id='post-blog'>
            <div className="container">
                <Headline label='Artigos de nosso blog' />
                <div className="post-wrappers">

                    {data.postsBlog.map((post, i) => (
                        <article key={i}>
                            <img src={post.image} alt={post.title}/>
                            <div className="wrapper">
                                <h2>{post.title}</h2>
                                <p>{post.text}</p>
                                <Link to={{ pathname: post.link}} target='_blank'>Continuar lendo</Link>
                            </div>
                        </article>
                    ))}
                    
                </div>
            </div>
        </section>
    );
}