import React from 'react';

import './styles.css';

export default function Topo() {
    return (
        <div id="survey-topo">
            <h1>Agendamento de <strong>vistoria</strong></h1>
            <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores 
                asperiores expedita, qui magnam iure autem a, ipsam ex hic
            </p>
        </div>
    );
}