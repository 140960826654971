import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import { FiArrowRight } from 'react-icons/fi';
import Utils from '../../helpers/Utils';
import Favorites from '../../helpers/Favorites';

import './styles.css';

export default function CardImovel(props) {
    const options = {
        items: 1,
        nav: false,
        dots: false
    };

    const _favorites = new Favorites();

    const carousel = useRef(null);

    const getSlug = (tipo, quartos, suites, bairro, cidade, estado) => {
        let dormitorios_total = quartos + suites;
        let slug = '';

        if (dormitorios_total === 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorio-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else if (dormitorios_total > 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorios-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else {
            slug = `${tipo}-no-bairro-${bairro}-${cidade}-${estado}`;
        }

        return Utils.transliterate(slug);
    }

    const link = `/detalhes/${props.data.modalidade}/${props.data.id}/${getSlug(props.data.tipo, props.data.quartos, props.data.suites, props.data.bairro, props.data.cidade, props.data.estado)}`;
    

    return(
        <div id={`card-content-${props.data.id}`} className="card-imovel">
            <div className="card-image">
                <OwlCarousel ref={carousel} options={options} >
                    {props.data.imagens.map((item, index) => (
                        <div key={index} className="item">
                            <Link to={link}>
                                <img src={item.imagem} alt={item.descricao}/>
                            </Link>
                        </div>                    
                    ))}
                </OwlCarousel>

                <div className="carousel-nav">
                    <span onClick={() => carousel.current.prev()}><i className="fas fa-angle-left"/></span>
                    <span onClick={() => carousel.current.next()}><i className="fas fa-angle-right"/></span>
                </div>
            </div>
            <div className="card-wrapper">
                <h2>
                    <Link to={link}>
                        {props.data.titulo}
                    </Link>
                </h2>
                <address>
                    <Link to={link}>
                        {props.data.bairro}, {props.data.cidade} - {props.data.estado}
                    </Link>
                </address>

                <p>
                    {props.data.descricao}
                </p>

                <ul className="card-account">
                    {props.condominioIncluido && props.data.modalidade === 'aluguel' ? 
                        <li>
                            <span> R$ {props.data.valor_total}
                            </span>(Aluguel: {props.data.valor} + Cond: {props.data.valor_condominio})
                        </li>
                        :
                        <li><span> R$ {props.data.valor.replace(',00','')}</span>Condomínio: R$ {props.data.valor_condominio}</li> 
                    }
                    <li><span>{props.data.area_total.replace(',00','')}m²</span>de área</li>
                </ul>

                <ul className="card-feature">
                    <li> Cód: {props.data.codigo}</li>
                    <li>{props.data.quartos} quarto{props.data.quartos > 0 ? 's':''}</li>
                    <li>{props.data.suites} suíte{props.data.suites > 0 ? 's':''}</li>
                    <li>{props.data.banheiros} banheiro{props.data.banheiros > 0 ? 's':''}</li>
                    <li>{props.data.vagas} vaga{props.data.vagas > 0 ? 's':''}</li>
                </ul>                

                <div className="card-buttons">
                    <button type="button" onClick={() => _favorites.update(props.data)}>
                        <i  id={`icon_${props.data.id}`}                            
                            className={_favorites.getIcons(props.data.id, props.icon)}
                        />
                    </button>
                    <Link to={link}>
                        <FiArrowRight /> Detalhes do imóvel
                    </Link>
                </div>                
            </div>
        </div>
    )
}