import React from 'react';
import  { FiPocket, FiThumbsUp, FiStar } from 'react-icons/fi';
import Headline from '../../../components/Headline';

import './styles.css';

export default function Benefits() {
    return (
        <section id="benefits-property-registration">
            <div className="container">
                <Headline label="Vantagens para ser nosso cliente" />

                <div className="benefits-box">
                    <article>
                        <FiThumbsUp />
                        <h2>Atendimento</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                            Fugit veniam architecto voluptatum, beatae dignissimo.
                        </p>
                    </article>
                    <article>
                        <FiStar />
                        <h2>Garantia</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                            Fugit veniam architecto voluptatum, beatae dignissimo.
                        </p>
                    </article>
                    <article>
                        <FiPocket />
                        <h2>Segurança</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                            Fugit veniam architecto voluptatum, beatae dignissimo.
                        </p>
                    </article>
                    <article>
                        <FiThumbsUp />
                        <h2>Atendimento</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                            Fugit veniam architecto voluptatum, beatae dignissimo.
                        </p>
                    </article>
                    <article>
                        <FiStar />
                        <h2>Garantia</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                            Fugit veniam architecto voluptatum, beatae dignissimo.
                        </p>
                    </article>
                    <article>
                        <FiPocket />
                        <h2>Segurança</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                            Fugit veniam architecto voluptatum, beatae dignissimo.
                        </p>
                    </article>
                    </div>
            </div>
        </section>
    )
}