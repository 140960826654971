import React, { useEffect } from  'react';
import Helmet from 'react-helmet';
import Header from './Header';
import Search from './Search';
import FeaturedProperties from './FeaturedProperties';
import FeaturedServices from './FeaturedServices';
import PopularProperties from './PopularProperties';
import Benefits from './Benefits';
import PostBlog from './PostBlog';
import Footer from '../../components/Layout/Footer';
import WhatsApp from '../../components/Layout/Whatsapp';

export default function Home() {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return (
        <>
            <Helmet title={`${process.env.REACT_APP_NAME}`} />
            <Header />
            <main>
                <Search />
                <FeaturedServices />
                <FeaturedProperties />
                <PopularProperties />
                <Benefits/>
                <PostBlog />
            </main>
            <WhatsApp/>
            <Footer/>
        </>
    );
}