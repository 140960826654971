import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import Swal from 'sweetalert2';
import LoadingMask from "react-loadingmask";
import { Form, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import api from '../../../../services/api';

import './styles.css';

const FormSurvey = (props) => {
    const {
        className
    } = props;

    const [state, setState] = useState({
        nome: '',
        celular: '',
        email: '',
        mensagem: `Olá, gostaria de agendar uma visita para o imóvel no bairro ${props.imovel.bairro}, ${props.imovel.cidade} - ${props.imovel.estado}. O código do imóvel é ${props.imovel.id}. Aguardo retorno.`
    });

    const [loading, setLoading] = useState(false);

    const handleOnChange = (e) => { 
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const clear = () => {
        setState({
          nome: '',
          celular: '',
          email: '',
          mensagem: `Olá, gostaria de agendar uma visita para o imóvel no bairro ${props.imovel.bairro}, ${props.imovel.cidade} - ${props.imovel.estado}. O código do imóvel é ${props.imovel.id}. Aguardo retorno.`  
        })
    }

    const handleSubmitSolicitarInformacoes = (event) => {
        event.preventDefault();
        setLoading(true);
    
        let dados =
        {
            "codigo": props.imovel.id,
            "modalidade": props.imovel.modalidade,
            "link": window.location.href,
            "titulo": props.imovel.titulo,
            "nome": state.nome,
            "email": state.email,
            "celular": state.celular,
            "mensagem": state.mensagem
        }
    
        api.post('/email/agendamentoDeVisita', dados).then( response => {
          setLoading(false);
    
          if (response.data.code === 1) {
              getAlert('success', 'Dados enviado com sucesso!');
              props.toggle();
              clear();
          }
    
          if (response.data.code === 2) {
              getAlert('error', 'Ooops! Erro no envio dos dados.');
          }    
        });
    }
      
    const getAlert = (icon, message) => {
        return Swal.fire({
            position: 'center',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
        });
    }

    return (
        <Modal isOpen={props.isOpen} toggle={()=>props.toggle()} className={className}>
            <ModalHeader toggle={()=>props.toggle()}>Agendamento de visita</ModalHeader>
            <ModalBody>
                <LoadingMask loading={loading} >
                <Form className="form-survey" onSubmit={event => handleSubmitSolicitarInformacoes(event)}>
                    <Input 
                        name="nome"
                        type="text"
                        placeholder="Seu nome"
                        required={true}
                        onChange={(event)=>handleOnChange(event)}
                        value={state.nome} />

                    <MaskedInput
                        className="form-control"
                        name='celular'
                        mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="Seu celular"                            
                        guide={false}
                        required={true}
                        onChange={(event)=>handleOnChange(event)}
                        value={state.celular} />

                    <Input
                        name="email"
                        type="email"
                        placeholder="Seu e-mail"
                        onChange={(event)=>handleOnChange(event)}
                        value={state.email} />

                    <Input
                        name="mensagem"
                        type="textarea"
                        required={true}
                        rows="4"
                        onChange={(event)=>handleOnChange(event)}
                        value={state.mensagem} />

                    <Button type="submit">Agendar visita</Button>       
                </Form>
                </LoadingMask>
            </ModalBody>
        </Modal>
    );
}

export default FormSurvey;