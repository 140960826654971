import React from 'react';
import  { FiPocket, FiThumbsUp, FiStar } from 'react-icons/fi';
import Headline from '../../../components/Headline';

import './styles.css';

export default function Benefits() {
    return (
        <section id="benefits">
            <Headline label='Vantagens para ser nosso cliente' />
            <div className="container">
                <article>
                    <FiThumbsUp />
                    <h2>Anúncio Grátis</h2>
                    <p>
                        Seu imóvel será amplamente anunciado não só em nosso site, como também 
                        nos principais portais imobiliários do país, proporcionando uma 
                        divulgação qualificada de forma a atrair interessados nas 
                        características específicas de cada imóvel, sendo dispensável custo 
                        adicional por isso para nossos clientes, caso o imóvel seja alugado.
                    </p>
                </article>
                <article>
                    <FiStar />
                    <h2>Aluguel Facilitado</h2>
                    <p>
                        Além das tradicionais garantias locatícias – caução e fiança -, a Jordão 
                        Imobiliária oferece aos seus inquilinos formas inovadores de assegurar a 
                        locação do imóvel, tais como a contratação de seguro fiança ou títulos 
                        de capitalização, facilitando o procedimento e locando o imóvel mais 
                        rápido!
                    </p>
                </article>
                <article>
                    <FiPocket />
                    <h2>Avaliação dos Imóveis</h2>
                    <p>
                        A Jordão Imobiliária oferece equipe de avaliação dos imóveis desocupados 
                        com o fim de sugerir aos proprietários melhorias que tornaram seu imóvel 
                        mais valorizado pelo mercado, facilitando e acelerando as futuras 
                        locações.
                    </p>
                </article>
                <article>
                    <FiThumbsUp />
                    <h2>Consultoria Gratuita</h2>
                    <p>
                        A equipe da Jordão Imobiliária é composto por equipe com vasta 
                        experiência em locação de imóveis, prestando a nossos clientes suporte 
                        consultivo sobre as melhores alternativas de mercado para o imóvel 
                        ofertado no mercado, de forma a obter a melhor rentabilidade.
                    </p>
                </article>
                <article>
                    <FiStar />
                    <h2>Garantia</h2>
                    <p>
                        Fornecemos assessoria jurídica especializada sem custo adicional para 
                        nossos clientes, que contará com profissional capacitado na solução de 
                        disputas decorrentes dos contratos de locação, tanto administrativa 
                        (conciliações, mediações e protestos cartorários) quanto judicialmente 
                        (propositura de ações judiciais)*.
                    </p>
                </article>
                <article>
                    <FiPocket />
                    <h2>Assinatura Eletrônica</h2>
                    <p>
                        Oferecemos aos nossos clientes a comidade de realizar a assinatura dos 
                        contratos de locação de forma digital, contando com a mesma validade 
                        jurídico dos contratos impressos.
                    </p>
                </article>
            </div>
                       
        </section>
    );
}
