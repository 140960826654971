import React from 'react';
import { FiAlertOctagon } from 'react-icons/fi';

import './styles.css';

export default function Alert() {
    return (
        <div id="property-details-alert">
            <div className="container">
                <FiAlertOctagon/>
                <p>
                    As informações dos imóveis exibidos neste site, podem ser alteradas, inclusive quanto ao preço, sem 
                    aviso prévio. Os valores de condomínio e IPTU citados nesse site, são apenas a título de referência e 
                    devem ser levantados no momento da visita. A área do imóvel e idade aqui informadas são aproximadas 
                    devendo ser confirmadas pelo cliente no momento da visita ao imóvel ou em sua respectiva documentação, 
                    assim como o número de vagas.
                </p>
            </div>
        </div>
    );
}