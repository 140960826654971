import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const Breadcrumbs = () => {    
    return (
        <div className="breadcrumb-content">
            <div className="container">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to='/'>{process.env.REACT_APP_NAME}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        Agendamento de vistoria
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
        </div>
    );
}
export default Breadcrumbs;