import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import api from '../../../services/api';

import ListTypes from './ListTypes';
import Input from  './Input';

import videoHome from '../../../assets/videos/home.mp4';

import './styles.css';

export default function Search() {
    const history = useHistory();
    const [options, setOptions] = useState({residenciais:[], comerciais:[]});

    const [modality, setModality] = useState('aluguel');
    const [code, setCode] = useState('');


    const handleRadioChange = (event) => {
        setModality(event.target.value);
        listTiposImoveis(event.target.value);
    };

    const handleChange = (e) => {
        setCode(e.target.value);
    };    

    const handleSubmit = (e) => {
        e.preventDefault();
        history.push(`detalhes/${modality}/${code}`);
    }

    const listTiposImoveis =  async(modality) => {        
        await api.get(`/${modality}/tiposImoveisDistintosOptions`).then(
            response => {
                setOptions(response.data.options);
            }
        );
    }

    useEffect(() => {
        listTiposImoveis(modality);
    }, [modality]);
   
    return (
        <section id='search-section'>
            <video autoPlay loop muted>
                <source src={videoHome} type="video/mp4"/>
            </video>
            <div className="container">
                <h1>Realizar seu sonho <span>é o nosso compromisso</span></h1>

                <FormControl component="fieldset">            
                    <RadioGroup row aria-label="modalidade" value={modality} name="modalidade" onChange={handleRadioChange}>                            
                        <FormControlLabel value="aluguel" control={<Radio color="primary" />} label="Aluguel" />
                        <FormControlLabel value="venda" control={<Radio color="primary" />} label="Venda" />
                    </RadioGroup>
                </FormControl>

                <div className="search-wrapper">                    
                    <ListTypes modality={modality} label='Residenciais' list={options.residenciais} />
                    <ListTypes modality={modality} label='Comerciais' list={options.comerciais} />                    
                </div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <fieldset>                        
                        <Input 
                            type="text"
                            name="codigo"
                            text="Busque pelo código"
                            code={code}
                            handleChange={handleChange} 
                        />
                    </fieldset>
                    <button type="submit" >
                        <i className="fas fa-search"/>
                    </button> 
                </form>
            </div>
        </section>
    );
}
