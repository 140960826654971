import React, { useState }  from 'react';
import { Form, Input, Button } from "reactstrap";
import MaskedInput from 'react-text-mask';
import Swal from 'sweetalert2';
import LoadingMask from "react-loadingmask";

import api from '../../../services/api';

import './styles.css';

const tiposImoveis = [
    'Apartamento',
    'Cobertura',
    'Casa',
    'Casa em Condomínio',
    'Flat',
    'Galpão',
    'Lote em Condomínio',
    'Loja',
    'Lote',
    'Prédio',
    'Sala',
    'Vaga de Garagem'
];

export default function FormRegistration (){

    const [state, setState] = useState({
        nome: '',
        email: '',
        celular: '',
        telefone: '',
        bairro: '',
        tipo: 'Apartamento',
        modalidade: 'Aluguel'
    });

    const [loading, setLoading] = useState(false);

    const handleOnChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const clear = () => {
        setState({
            nome: '',
            email: '',
            celular: '',
            telefone: '',
            bairro: '',
            tipo: 'Apartamento',
            modalidade: 'Aluguel'
        })
    }

    const handleSubmitRegister = (event) => {
        event.preventDefault();
        setLoading(true);
        
        const {nome, email, celular, telefone, bairro, tipo, modalidade} = state;

        const dados = {   
            nome,
            email,
            celular,
            telefone,
            bairro,
            tipo,
            modalidade
        }

        api.post('/email/cadastrarImovel', dados).then( response => {
            setLoading(false);
    
            if (response.data.code === 1) {
                getAlert('success', 'Dados enviado com sucesso!');          
                clear();
            }
    
            if (response.data.code === 2) {
                getAlert('error', 'Ooops! Erro no envio dos dados.');
            }
    
        });
    }

    const getAlert = (icon, message) => {
        return Swal.fire({
            position: 'center',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
        });
    }

    return (
        <div className="block-form" >
            <LoadingMask loading={loading} >           
            <Form onSubmit={event => handleSubmitRegister(event)}>
                <div className="block-group">
                    <Input                         
                        type="select"
                        name="modalidade"
                        onChange={(event)=>handleOnChange(event)}
                        value={state.modalidade} >

                        <option value='Aluguel'>Aluguel</option>
                        <option value='Venda'>Venda</option>
                        <option value='Aluguel e venda'>Aluguel e venda</option>
                    </Input>                    
                    <Input
                        type="select"
                        name="tipo"
                        onChange={(event)=>handleOnChange(event)}
                        value={state.tipo} >
                        
                        {tiposImoveis.map((tipo, index) => (
                            <option key={index} value={tipo}>{tipo}</option>
                        ))}
                    </Input>
                </div>

                <Input                         
                    name="bairro"
                    type="text"
                    placeholder="Bairro e Cidade - Ex: Benfica - Fortaleza - CE"
                    required={true}
                    onChange={(event)=>handleOnChange(event)}
                    value={state.bairro} />

                <Input 
                    name="nome"
                    type="text"
                    placeholder="Seu nome"
                    required={true}
                    onChange={(event)=>handleOnChange(event)}
                    value={state.nome} />

                <Input
                    name="email"
                    type="email"
                    placeholder="Seu e-mail"
                    required={true}
                    onChange={(event)=>handleOnChange(event)}
                    value={state.email} />

                <div className="block-group">
                    <MaskedInput
                        className="form-control"
                        name='telefone'
                        mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="Seu telefone"                            
                        guide={false}
                        onChange={(event)=>handleOnChange(event)}
                        value={state.telefone} />

                    <MaskedInput
                        className="form-control"
                        name='celular'
                        mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="Seu celular"                            
                        guide={false}
                        required={true}
                        onChange={(event)=>handleOnChange(event)}
                        value={state.celular} />
                </div>

                <Button type="submit">Anunciar meu imóvel <span>na Jordão Imobiliária</span></Button>                                       
            </Form>
            </LoadingMask>
        </div>
    );
}