import React from 'react';

import './styles.css';

export default function Details(props) {
    return(
        <div id="details-content">
            <h1>{props.imovel.tituloDetalhes}</h1>
            <p>
                {props.imovel.descricao}
            </p>

            <ul>
                <li>
                    <span>Área</span>
                    <span><i className="fas fa-vector-square"/> {props.imovel.area_total}m² </span>
                </li>
                <li>
                    <span>Quarto{props.imovel.quartos > 1 ? 's':''}</span>
                    <span><i className="fas fa-bed"/> {props.imovel.quartos} </span>
                </li>
                <li>
                    <span>Suíte{props.imovel.suites > 1 ? 's':''}</span>
                    <span><i className="fas fa-bath"/> {props.imovel.suites} </span>
                </li>
                <li>
                    <span>Banheiro{props.imovel.banheiros > 1 ? 's':''}</span>
                    <span><i className="fas fa-shower"/> {props.imovel.banheiros} </span>
                </li>
                <li>
                    <span>Vaga{props.imovel.vagas > 1 ? 's':''}</span>
                    <span><i className="fas fa-car"/> {props.imovel.vagas} </span>
                </li>
                <li>
                    <span>Código</span>
                    <span><i className="fas fa-qrcode"/> {props.imovel.id} </span>
                </li>
            </ul>

            {props.imovel.caracteristicas.length > 0 ? 
            <div className="block-list-features">
                <h2>Detalhes do imóvel</h2>
                <ul>
                    {props.imovel.caracteristicas.map((item, index) => ( 
                        <li key={index}>
                            <i className="fas fa-check"/>&nbsp;
                            {item.nome}
                        </li>
                    ))}                            
                </ul>
            </div>
            :''}
            
            {props.imovel.condominio.length > 0 ? 
            <div className="block-list-features">
                <h2>Detalhes do edifício</h2>
                <ul>
                    {props.imovel.condominio.map((item, index) => ( 
                        <li key={index}>
                            <i className="fas fa-check"/>&nbsp;
                            {item.nome}
                        </li>
                    ))}                            
                </ul>
            </div>
            :''}
        </div>
    );
}