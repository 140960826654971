import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Breadcrumb from './Breadcrumbs';
import Topo from './Topo';
import Benefits from './Benefits';
import Footer from '../../components/Layout/Footer';
import WhatsApp from '../../components/Layout/Whatsapp';
import Instruction from './Instruction';

export default class PropertyRegistration extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <>
                <Helmet title={`Anuncie seu imóvel | ${process.env.REACT_APP_NAME}`} />
                <Header />
                <main>
                    <Breadcrumb />
                    <Topo />
                    <Benefits />
                    <Instruction />
                </main>
                <WhatsApp/>
                <Footer />
            </>
        );
    }
}