import React from 'react';
import { FiSmile } from 'react-icons/fi';
import FormRegistration from './FormRegistration';

import './styles.css';

export default function Topo() {
    return (
        <section id="topo-property-registration">
        <div className="container">
            <div className="content">
                <h1>Anuncie seu imóvel na <strong>Jordão Imobiliária</strong></h1>
                <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repellendus tempore expedita quisquam voluptate 
                    asperiores dolorum, doloribus quos consequatur omnis repudiandae!
                </p>

                <ul>
                    <li>
                        <i className="fas fa-check" />
                        <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. <strong>Repellendus tempore expedita quisquam</strong></span>
                    </li>
                    <li>
                        <i className="fas fa-check" />
                        <span>Lorem ipsum dolor <strong>sit amet consectetur</strong>, adipisicing elit. Repellendus tempore expedita quisquam</span>
                    </li>
                    <li>
                        <i className="fas fa-check" />
                        <span>Lorem ipsum dolor sit amet. <strong>Repellendus tempore expedita quisquam</strong></span>
                    </li>
                    <li>
                        <i className="fas fa-check" />
                        <span>Lorem ipsum dolor sit amet consectetur, <strong>adipisicing elit</strong>. Repellendus tempore</span>
                    </li>
                </ul>

                <div className="block-alert">
                    <FiSmile/>
                    <h2>Preencha o formulário e deixe que a gente faça o trabalho pesado por você!</h2>
                </div>
            </div>

            <div className="content-form">
                <FormRegistration />
            </div>
        </div>

    </section>
    )
}