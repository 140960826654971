import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import ReactBnbGallery from 'react-bnb-gallery';
import { FiCamera } from 'react-icons/fi'

import 'react-bnb-gallery/dist/style.css';
import './styles.css';

export default function Gallery({data}) {
    const [open, setOpen] = useState(false);    
    const [numberPhoto, setNumberPhoto] = useState(0);
    const [photos, setPhotos] = useState([]);

    const options = {
        nav: false,
        dots: false,
        margin: 2,
        responsive: {0: {items: 1}, 768: {items: 2}, 990: {items: 3}}
    };

    const handleGallety = (index) => {
        setOpen(true);
        setNumberPhoto(index);
    }

    const handleLoadImagesForCarousel = (data) => {
        const images = [];
        data.forEach(img => images.push(img.imagem))
        setPhotos(images);
    }

    useEffect(()=>{
       handleLoadImagesForCarousel(data)
    },[data]);

    return (
        <div id="gallery-content">     
            <button onClick={() => handleGallety(0)}>                
                <FiCamera size={20}/> &nbsp;
                <span> {photos.length} Fotos</span>
            </button>

            <div className="gallery">
                <OwlCarousel options={options} >
                    {data.map((item, index) => (
                        <div key={index} className="item">
                            <img src={item.imagem} alt={item.descricao} onClick={() => handleGallety(index)}/>
                        </div>  
                    ))}                                   
                </OwlCarousel>
            </div>

            <ReactBnbGallery
                show={open}
                photos={photos}
                onClose={()=>setOpen(false)}
                wrap = {true}
                opacity={.9}
                activePhotoIndex={numberPhoto}
                showThumbnails={false}
            /> 
        </div>
    );
}