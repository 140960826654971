import React from 'react';
import { Link } from 'react-router-dom';
import { FiUsers, FiUploadCloud, FiCalendar } from 'react-icons/fi';

import './styles.css';

export default function FeaturedServices() {
    return (
        <section id="featured-services">
            <div className="container">
                <article>
                    <Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank' >
                        <FiUsers size={50} color={'var(--color-primary'}/>
                        <span>
                            <h2>Área cliente</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adred adipisicing elit. Ipsam error</p>
                        </span>
                    </Link>
                </article>

                <article>
                    <Link to='/cadastre-seu-imovel'>
                        <FiUploadCloud size={50} color={'var(--color-primary'}/>
                        <span>
                            <h2>Anunciar meu imóvel</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adred adipisicing elit. Ipsam error</p>
                        </span>
                    </Link>
                </article>

                <article>
                    <Link to='/agendamento-de-vistoria'>
                        <FiCalendar size={50} color={'var(--color-primary'}/>
                        <span>
                            <h2>Vistoria ou devolução</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adred adipisicing elit. Ipsam error</p>
                        </span>
                    </Link>
                </article>
            </div>
        </section>
    );
}