import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Breadcrumb from './Breadcrumbs';
import Topo from './Topo';
import Content from './Content';
import Footer from '../../components/Layout/Footer';
import WhatsApp from '../../components/Layout/Whatsapp';

export default class Favorites extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <>
                <Helmet title={`Agendamento de vistoria | ${process.env.REACT_APP_NAME}`} />
                <Header />
                <main>
                    <Breadcrumb />
                    <Topo />
                    <Content />
                </main>
                <WhatsApp/>
                <Footer />
            </>
        );
    }
}