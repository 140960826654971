import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiKey, FiHome, FiAirplay } from 'react-icons/fi';

import './styles.css';

const Menu = () => {
    const closeDrawer = () => {
        document.querySelector('#drawer-menu-overlay').classList.add('no-overlay');
        document.querySelector('#drawer-menu').classList.add('no-drawer-menu');
    }

    return(
        <Fragment>
            <div id="drawer-menu-overlay" className="drawer-menu-overlay no-overlay" onClick={closeDrawer}></div>
            <div id="drawer-menu" className="drawer-menu no-drawer-menu">
                <FiX 
                    size={20} 
                    color={'var(--color-white)'}
                    onClick={closeDrawer} 
                    style={{cursor:'pointer', margin: '0 10px 10px'}}
                />

                <div className="box-buttons">
                    <Link to='/aluguel/apartamento/fortaleza' onClick={closeDrawer}>
                        <FiKey />
                        Imóveis para alugar
                    </Link>
                    <Link to='/venda/apartamento/fortaleza' onClick={closeDrawer}>
                        <FiHome />
                        Imóveis à venda
                    </Link>

                    <Link to={{pathname: '/favoritos'}} onClick={closeDrawer}>
                        <i className="far fa-heart"/>
                        Meus imóveis favoritos
                    </Link>

                    <Link to='/cadastre-seu-imovel' onClick={closeDrawer}>
                        <FiAirplay />
                        Anunciar meu imóvel
                    </Link>
                </div>
                <hr/>
                <div className="box-services">
                    <Link to='/agendamento-de-vistoria' onClick={closeDrawer}>
                    <i className="far fa-calendar-check"/>
                        Agendamento de vistoria
                    </Link>
                    <Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank' onClick={closeDrawer}>
                        <i className="fas fa-barcode"/>
                        Segunda via de boleto
                    </Link>
                    <Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank' onClick={closeDrawer}>
                        <i className="fas fa-calculator"/>
                        Prestação de contas
                    </Link>
                    <Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank' onClick={closeDrawer}>
                        <i className="fas fa-dollar-sign"/>
                        Extrato de IRRF
                    </Link>
                </div>
            </div>
        </Fragment>
    );
}

export default Menu;