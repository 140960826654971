import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import './styles.css';

export default class Error404 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <>
                <Helmet title={`Error 404 | ${process.env.REACT_APP_NAME}`} />
                <main>
                    <div className="page-error-404">
                        <div className="container">
                            <h2>...oops</h2>
                            <h1>Error <span>404</span></h1>
                            <p>
                                Não encontramos a página que você está procurando. 
                                Pode ser que você tenha digitado o endereço da página incorretamente.
                            </p>
                            <Link to='/'>Página inicial</Link>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}