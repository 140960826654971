import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import SearchResult from './pages/SearchResult';
import PropertyDetails from './pages/PropertyDetails';
import AboutUs from './pages/AboutUs';
import Favorites from './pages/Favorites';
import PropertyRegistration from './pages/PropertyRegistration';
import Survey from './pages/Survey';
import Error404 from './pages/Error404';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={ Home } />
        <Route exact path="/detalhes/:modalidade/:codigo/:slug?" component={PropertyDetails} />
        <Route exact path="/:modalidade/:tipo/:endereco/:filtros?" component={SearchResult} />
        <Route exact path="/sobre-nos" component={AboutUs} />
        <Route exact path="/favoritos" component={Favorites} />
        <Route exact path="/cadastre-seu-imovel" component={PropertyRegistration} />
        <Route exact path="/agendamento-de-vistoria" component={Survey} />
        <Route exact path="*" component={Error404} />
      </Switch>
    </BrowserRouter>
  );  
}