import React from  'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { FiKey, FiHome, FiPhoneCall, FiGrid } from 'react-icons/fi';
import Favorites from '../../../helpers/Favorites';

import Menu from '../../../components/Layout/Menu';

import logomarca from './../../../assets/images/logomarca.png';

import './styles.css';

export default function Header() {
    const openDrawer = () => {
        document.querySelector('#drawer-menu').classList.remove('no-drawer-menu');
        document.querySelector('#drawer-menu-overlay').classList.remove('no-overlay');
    }

    const _favorites = new Favorites();

    return (
        <header>
            <div className="container">
                <Link to='/'>
                    <img src={logomarca} alt={process.env.REACT_APP_NAME}/>
                </Link>

                <div className="wrapper">
                    <div className="block-contact">                        
                        <ul>
                            <li>
                                <Link to={{pathname: process.env.REACT_APP_WHATSAPP_API }} target="_blank">
                                    <FaWhatsapp size={35} color={'var(--color-primary)'}/>
                                    <span>
                                        Whatsapp
                                        <strong>{process.env.REACT_APP_DDD} {process.env.REACT_APP_WHATSAPP}</strong>
                                    </span>
                                </Link>
                            </li>                        
                            <li>
                                <FiPhoneCall size={30} color={'var(--color-primary)'}/>
                                <span>
                                    Nosso contato
                                    <strong>
                                        {process.env.REACT_APP_DDD} {process.env.REACT_APP_PHONE}
                                    </strong>
                                </span>
                            </li>                        
                        </ul>                   
                    </div>
                    <nav>
                        <ul>
                            <li>
                                <Link to='/aluguel/apartamento/fortaleza'>
                                    <FiKey size={18} color='var(--color-primary)'/>&nbsp;
                                    Aluguel
                                </Link>
                            </li>
                            <li>
                                <Link to='/venda/apartamento/fortaleza'>
                                    <FiHome size={17} color='var(--color-primary)'/>&nbsp;
                                    Venda
                                </Link>
                            </li>
                            <li>
                                <Link to='/favoritos'>
                                    <i className="far fa-heart"/>
                                    <span id='totalizadorFavorites' className="fav-count">{_favorites.getQtdFavorites()}</span>
                                    Favoritos
                                </Link>
                            </li>
                            <li>
                                <Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank' >
                                    <i className="far fa-user"/>
                                    Login
                                </Link>
                            </li>
                            <li>
                                <FiGrid onClick={openDrawer} size={24} color='var(--color-primary)'/>
                            </li>
                        </ul>
                    </nav>

                    <Menu />
                </div>
            </div>
        </header>
    )
}
