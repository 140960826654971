import React from 'react';
import Pagination from "react-js-pagination";
import LoadingMask from "react-loadingmask";
import FormSearch from './FormSearch';
import Topo from './Topo';
import CardImovel from '../../../components/CardImovel';

import './styles.css';

export default function Content(props) {
    let paginacao;
        if (props.total > props.limit) {
            paginacao =             
                <div className="content-pagination">
                    <nav>
               
                        <Pagination
                            hideDisabled
                            prevPageText='Anterior'
                            nextPageText='Próximo'
                            firstPageText='Início'
                            lastPageText='Fim'
                            activePage={props.page}
                            itemsCountPerPage={props.limit}
                            totalItemsCount={parseInt(props.total)}
                            onChange={props.handlePageChange}
                        />
             
                    </nav>
                </div>
        } else {
            paginacao = ''
        }
    return(
        
        <div id='search-result-container'>
            
            <FormSearch {...props} />

            <div className="content-search-result">
                <Topo {...props} />

                <LoadingMask loading={props.loadingContent} >
                    {props.imoveis.map((imovel, index) => (
                        <CardImovel 
                            key={index} 
                            data={imovel}
                            icon='heart' 
                            condominioIncluido={props.filtros.condominioIncluido === 'sim'? true:false}/>
                    ))}

                    { paginacao }
                </LoadingMask>
            </div>
        </div>
        
    );
}