import React, { useState } from 'react';

import './styles.css';

export default function Location(props) {
    const [viewMap, setViewMap] = useState(false)

    return(
        <div className="block-location">
            <h2>Localização</h2>
            <h3>{props.imovel.endereco + (props.imovel.complemento ? " - " + props.imovel.complemento : "")} - {props.imovel.bairro}, {props.imovel.cidade} - {props.imovel.estado}</h3>
            <div className="block-map">

                <div className="content-map">                                    
                    <span onClick={() => ( 
                        setViewMap(true)
                    )}>
                    Ver no mapa</span>
                </div>
                
                {viewMap &&
                    <iframe 
                        src={"https://maps.google.com/maps?q="+props.imovel.endereco+"-"+props.imovel.cidade+"-"+props.imovel.estado+"&spn=0.043344,0.063343&t=M&hl=pt&output=embed"}
                        width="100%"
                        height="100%"
                        style={{border:'0'}}
                        title="localizacao" >
                    </iframe>
                }
            </div>
        </div>
    );
}
