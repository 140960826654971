import React from 'react';
import Headline from '../../../components/Headline';
import CardImovel from '../../../components/CardImovel';
import Favorites from '../../../helpers/Favorites';

import './styles.css';

export default function Content() {
    const _favorites = new Favorites();

    return (
        <div id="favorites-main">
            <Headline label={ _favorites.getQtdFavorites() > 0 ? 'Meus imóveis favoritos' : 'Você ainda não favoritou imóveis'} />

            <div className="container">
                { _favorites.getQtdFavorites() > 0 ?
                    _favorites.getFavorites().map(card => (
                        <CardImovel 
                            key={card.id} 
                            data={card}
                            icon='trash'
                        />)) 
                    :
                    <div className="no-favorites">                      
                        <h2>Como adicionar um imóvel aos meus favoritos?</h2>                   
                        <p>
                            Na página de busca ou na página do imóvel, clique no botão
                            &nbsp;<i className="far fa-heart" />&nbsp;
                            para adicionar aos favoritos.
                        </p>
                    </div>
                }            
            </div>
        </div>
    );    
}