import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

import whatsapp from '../../../assets/images/icons/whatsapp.png';

export default function WhatsApp() {
    return (

        <div className="block-whatsapp">
            <Link to={{pathname: process.env.REACT_APP_WHATSAPP_API}} target="_blank">
                <img src={whatsapp} alt="Contato via WhatsApp"/>
            </Link>
        </div>
    )
}